<template>
    <div class="page" :class="currentRoute ? 'page--application-steps' : 'page--application'" >
        <div class="container">
            <a href="/" class="logo"><img src="/img/logo.svg"></a>
            <div class="section-header">
                <div class="section-header__title title">Оформление заявки на обучение</div>
                <div class="section-header__subtitle">Заполните заявку и приступите к обучению уже через 5 минут!</div>
            </div>
            <RouterView></RouterView>
        </div>
    </div>
</template>

<script>
import store from "@/store/index.js"

export default {
    beforeRouteEnter(to, from, next) {
        if (to.redirectedFrom) {
            const uid = to.redirectedFrom.query.uid
            if (uid) {
                store.dispatch("APPLICATION_SET_LOCAL_STORAGE", uid).then((response) => {
                    localStorage.setItem('courses', JSON.parse(response.data.localstorage))
                });
            }
        }
        next();
    },
    computed: {
        currentRoute() {
            return this.$route.path === '/application/courses'
        }
    }
}
</script>
